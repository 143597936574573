<template>
  <div>
    <div class="master">
      <div class="top-bar">
        <div class="title">
          <span class="tag" @click="toWelcomePage">
            <label>盛魁会展</label>
            <el-divider direction="vertical"></el-divider>
            <label>展会管理系统</label>
          </span>
        </div>
        <div class="tool-bar">
          <el-button type="text" @click="toPersonalPage">欢迎你，{{user.Name}}</el-button>
          <el-tooltip class="item" effect="dark" :content="logouting ? '安全退出中...' : '退出登录'" placement="left">
            <el-button type="text" class="oa-text-danger" @click="logout">
              <font-awesome-icon fas icon="sign-out-alt"></font-awesome-icon>&nbsp;
            </el-button>
          </el-tooltip>
        </div>
      </div>
      <div class="main-content">
        <!-- 左侧导航 -->
        <el-menu class="left-menu" background-color="#303133" text-color="#fff" active-text-color="#ffd04b">
          <el-submenu v-if="user.Role === 0" index="1">
            <template slot="title">
              <font-awesome-icon fas icon="cogs"></font-awesome-icon>&nbsp;
              <span>系统设置</span>
            </template>
            <el-menu-item index="1-1" @click="toUserPage">账号角色</el-menu-item>
            <el-menu-item index="1-2" @click="toLoginLogPage">登录日志</el-menu-item>
          </el-submenu>
          <el-submenu v-if="user.Role === 1" index="2">
            <template slot="title">
              <font-awesome-icon fas icon="comment-dollar"></font-awesome-icon>&nbsp;
              <span>基础信息</span>
            </template>
            <el-menu-item index="2-1" @click="toActivityPage">项目管理</el-menu-item>
            <!-- <el-menu-item index="2-2" @click="toRegistrationUserPage">注册用户</el-menu-item>
            <el-menu-item index="2-3" @click="toOrderPage">订单管理</el-menu-item> -->
            <el-menu-item index="2-4" @click="toOrganizerPage">客户管理</el-menu-item>
          </el-submenu>
          <el-submenu v-if="user.Role === 2" index="3">
            <template slot="title">
              <font-awesome-icon fas icon="comment-dollar"></font-awesome-icon>&nbsp;
              <span>数据管理</span>
            </template>
            <el-menu-item index="3-1" @click="toPersonalActivityPage">项目管理</el-menu-item>
            <!-- <el-menu-item index="3-2" @click="toPersonalRegistrationUserPage">注册用户</el-menu-item> -->
            <!-- <el-menu-item index="3-3" @click="toPersonalOrderPage">订单管理</el-menu-item> -->
          </el-submenu>
        </el-menu>
        <div class="right-content">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '../../apis/ticket-api'
import {
  WELCOME,
  INDEX,
  LOGIN,
  LOGIN_LOG,
  USER,
  PERSONAL,
  ACTIVITY,
  REGISTRATION_USER,
  ORGANIZER,
  ORDER,
  PERSONAL_ACTIVITY,
  PERSONAL_REGISTRATION_USER,
  PERSONAL_ORDER
} from '../../router/base-router'
import { SET_USER } from '../../store/mutation-types'

export default {
  name: INDEX.name,
  data () {
    return {
      user: {},
      loading: false, // 加载中
      logouting: false // 退出中
    }
  },
  methods: {
    logout () {
      this.$cookies.set('user', '')
      this.$router.push(LOGIN)
    },
    toWelcomePage () {
      this.$router.push(WELCOME)
    },
    toUserPage () {
      this.$router.push(USER)
    },
    toLoginLogPage () {
      this.$router.push(LOGIN_LOG)
    },
    toPersonalPage () {
      this.$router.push(PERSONAL)
    },
    toActivityPage () {
      this.$router.push(ACTIVITY)
    },
    toRegistrationUserPage () {
      this.$router.push(REGISTRATION_USER)
    },
    toOrganizerPage () {
      this.$router.push(ORGANIZER)
    },
    toOrderPage () {
      this.$router.push(ORDER)
    },
    toPersonalActivityPage () {
      this.$router.push(PERSONAL_ACTIVITY)
    },
    toPersonalRegistrationUserPage () {
      this.$router.push(PERSONAL_REGISTRATION_USER)
    },
    toPersonalOrderPage () {
      this.$router.push(PERSONAL_ORDER)
    }
  },
  created () {
    if (this.$store.getters.access_token) {
      this.axios.get(API.PERSONAL.URL).then(response => {
        if (response) {
          this.user = response
          this.$store.commit(SET_USER, response)
          if (this.user.Role === 1) {
            this.toActivityPage()
          } else if (this.user.Role === 2) {
            this.toPersonalActivityPage()
          } else {
            this.toUserPage()
          }
        }
      })
    } else {
      this.logout()
    }
  }
}
</script>

<style lang="scss">
$font-color:#434a50;
$top-height:50px;

.master {
  position: fixed;
  width: 100%;
  height: 100%;
}

.top-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 51px;
  color: $font-color;

  .title {
    display: flex;
    width: 250px;
    min-width: 250px;
    padding-right: 10px;

    .tag {
      display: flex;
      align-items: center;
      margin-left: 10px;
      cursor: pointer;

      label {
        font-size: 18px;
        font-family: 'SourceHanSansCN-Normal';
      }

      img {
        height: 26px;
      }
    }

    button {
      font-size: 18px !important;
      padding: 0;
    }
  }

  .squre-btn {
    width: 50px;
    border-radius: 0;
    border: none;
    transition: all 0.2s ease-in;
  }

  .tool-bar {
    display: flex;
    padding-right: 10px;
    height: 100%;
  }
}

.main-content {
  display: flex;
  height: 100%;

  .left-menu {
    width: 250px;;
    overflow-y: auto;
    margin-bottom: 50px;
  }

  .right-content {
    flex: 1;
    height: 100%;
    padding: 20px;
    overflow-y: auto;
  }
}
</style>
